import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          alignCenter
          justifyStart
        >
          <CFView mt="50px" textCenter w="100%">
            <CFImage
              w="90%"
              src={mobileHeroText}
              alt="Sushi Mago Japaneese Restaurant hero text"
              zIndex={98}
            />
            <CFView pulsate mt="20px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="600px"
          maxWidth="1400px"
          image={`url(${hero}) right / cover no-repeat`}
          w="100%"
          zIndex={90}
          row
          justifyBetween
        >
          <CFView column justifyCenter alignStart zIndex={99} ml="5%">
            <CFImage
              w="45vw"
              maxWidth="550px"
              src={heroText}
              alt="Sushi Mago Japaneese Restaurant hero text"
              zIndex={98}
            />
            <CFView pulsate mt="35px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
